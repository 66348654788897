@import 'npm:mapbox-gl/dist/mapbox-gl.css';

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  min-height: calc(100% + constant(safe-area-inset-top));
  min-height: calc(100% + env(safe-area-inset-top));
  font-family: 'Montserrat', 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  text-size-adjust: none;
  background-color: #fff;
}

p,
img {
  vertical-align: middle;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
input{
  font-size: 1rem;
}
button, select {
  font-family: inherit;
  font-size: inherit;
}
a {
  color: #007aff;
}
a:active {
  filter: brightness(0.8);
}
.navbar{
  height: 100px;
}
.navbar-brand{
  margin: 0 1rem 0 1rem;
}
#map {
  position: fixed;
  top:100px;
  bottom: 70px;
  width: 100%;
  transition: filter 0.3s ease-in-out;
  background-color: #fff;
  display: none;
}
#map.fade-out {
  pointer-events: none;
  filter: brightness(0.7);
}
#map.fade-out + header {
  pointer-events: none;
}
.footer{
  position: fixed;
  bottom: 0;
}
.mapboxgl-canvas {
  outline: 0;
}
.mapboxgl-ctrl-group {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4) !important;
}
@supports (backdrop-filter: blur(10px)) {
  .mapboxgl-ctrl-group {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}
.mapboxgl-style-switcher:hover {
  border-radius: 50px !important;
}
.mapboxgl-ctrl-group button {
  opacity: 1;
  width: 36px;
  height: 36px;
}
.mapboxgl-ctrl-zoom-in:hover{
  border-radius: 40px 40px 0 0 !important;
}
.mapboxgl-ctrl-zoom-out:hover{
  border-radius: 0;
}
.mapboxgl-ctrl-compass:hover{
  border-radius: 0 0 40px 40px !important;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  box-sizing: content-box;
}
@supports (top: max(1px)) {
  .mapboxgl-ctrl-top-right {
    top: max(0px, env(safe-area-inset-top));
    right: max(0px, env(safe-area-inset-right));
  }
}
.mapboxgl-ctrl-bottom-left {
  transform: translateY(-60px);
  transition: transform 0.3s ease-in-out;
}
@supports (top: max(1px)) {
  .mapboxgl-ctrl-bottom-left {
    transform: translateY(calc(-60px - env(safe-area-inset-bottom)));
  }
}

#logo {
  top: calc(12px + env(safe-area-inset-top));
  left: 12px;
  z-index: 1;
  cursor: pointer;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
  transition: opacity 0.3s ease-in-out;
}
@supports (top: max(1px)) {
  #logo {
    left: max(12px, env(safe-area-inset-left));
  }
}
#logo.fadeout {
  opacity: 0.5;
}
#logo.fadeout:hover {
  opacity: 1;
}
#logo:active {
  transform: scale(0.95);
  filter: brightness(0.9);
}
#logo h1 {
  margin: 0;
  padding: 0;
}
.popover {
  position: fixed;
  width: 100%;
  max-width: inherit;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px 15px 0 0;
  z-index: 2;
}
@supports (backdrop-filter: blur(10px)) {
  .popover {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}

#search-popover {
  top: 162px;
  font-family: inherit;
  height: 100%;
  bottom: 0;
  transform: translateY(55%);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
@supports (top: min(1px)) {
  #search-popover {
    top: min(62px, calc(62px - env(safe-area-inset-bottom) + 14px));
  }
}
#search-popover.shrink {
  transform: translateY(calc(100% - 183px));
}
#search-popover.shrink:not(.expand) .popover-list {
  opacity: 0.8;
}
#search-popover.expand {
  transform: translateY(80px);
  transform: translateY(
    calc(80px + env(safe-area-inset-top) + env(safe-area-inset-bottom))
  );
  z-index: 3;
}
#search-popover.loading {
  opacity: 0.75;
  pointer-events: none;
}
#search-popover .popover-inner {
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@supports (backdrop-filter: blur(10px)) {
  #search-popover {
    background-color: transparent;
    backdrop-filter: none;
  }
  #search-popover .popover-inner {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}

#stop-popover,
#between-popover,
#arrivals-popover,
#service-popover {
  top: calc(100% - 70px);
  transition: all 0.3s 0.1s ease-in-out;
  max-height: 50vh;
  display: flex;
  font-family: inherit;
  flex-direction: column;
}
#stop-popover.expand,
#between-popover.expand,
#service-popover.expand {
  transform: translateY(-100%);
}
#stop-popover.expand {
  z-index: 3;
}
#arrivals-popover.expand {
  transform: translateY(-70%);
  z-index: 4;
}
#stop-popover.expand ~ #service-popover.expand {
  transform: translateY(-70px);
  pointer-events: none;
}
#stop-popover header,
#between-popover header,
#service-popover header {
  flex-shrink: 0;
  padding: 12px 60px 0 12px;
}
#stop-popover h1,
#between-popover h1,
#service-popover h1 {
  font-size: 1em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
}
#stop-popover h1 .stop-tag,
#between-popover h1 .stop-tag,
#service-popover h1 .service-tag {
  margin: 0 0.5em 0 0;
}
#service-popover h1 .service-tag {
  margin-top: -0.2em;
}
#stop-popover header h1 {
  cursor: pointer;
}
#stop-popover h2,
#between-popover h2,
#service-popover h2 {
  font-size: 0.8em;
  font-weight: 600;
  margin: 0.5em 0 0;
  padding: 0;
  color: #383838;
  text-transform: uppercase;
}
#stop-popover h2,
#service-popover h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
}
#stop-popover h2 a,
#service-popover h2 a {
  color: inherit;
  text-decoration: none;
}
#stop-popover h2 a:hover,
#service-popover h2 a:hover {
  text-decoration: underline;
}
#stop-popover h2 img.new-window,
#service-popover h2 img.new-window {
  filter: invert(1) opacity(0.4);
}
#stop-popover h3,
#between-popover h3,
#service-popover h3 {
  font-size: 0.7em;
  margin: 1em 0 0.5em;
  padding: 0;
  color: #666;
  text-transform: uppercase;
}
#stop-popover p,
#between-popover p,
#service-popover p {
  margin: 0;
  padding: 0;
}
#stop-popover .popover-scroll,
#between-popover .popover-scroll,
#service-popover .popover-scroll {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 12px;
}
#stop-popover .popover-scroll {
  padding-bottom: 0;
  padding-top: 0;
}
#between-popover .popover-scroll,
#service-popover .popover-scroll {
  padding-top: 0;
}
#stop-popover .popover-footer {
  padding: 12px;
}
@supports (top: max(1px)) {
  #between-popover .popover-scroll {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
  #service-popover .popover-scroll {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
  #stop-popover .popover-footer {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
}

#stop-popover .services-list span {
  transition: opacity 0.3s;
}
#stop-popover .services-list.loading span {
  opacity: 0.75;
  pointer-events: none;
}

#between-popover .between-block {
  margin: 1em 0;
}

#between-popover .between-nada {
  font-size: 14px;
  color: #333;
}

#between-popover .between-item {
  border-radius: 12px;
  padding: 12px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  margin-bottom: 6px;
  cursor: pointer;
}
#between-popover .between-item:hover {
  border-color: #a4d0ff;
  background-color: #fff;
}
#between-popover .between-item.selected {
  border-color: #007aff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

#between-popover .between-inner {
  pointer-events: none;
  position: relative;
  height: 40px;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}
#between-popover .between-item:hover .between-inner,
#between-popover .between-item.selected .between-inner {
  opacity: 1;
}

#between-popover .between-services {
  font-size: 14px;
}
#between-popover .between-services span {
  position: absolute;
  width: 70%;
  text-align: center;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: bottom;
}
#between-popover .between-services .start {
  padding-right: 2em;
  padding-bottom: 2px;
  left: 5px;
  background-image: linear-gradient(to left, transparent 0%, #f01b48 30%);
}
#between-popover .between-services.full .start {
  width: auto;
  right: 5px;
}
#between-popover .between-services.full .start {
  background-image: linear-gradient(to left, #972ffe, #f01b48);
  padding-right: 0;
}
#between-popover .between-services .end {
  padding-left: 2em;
  padding-bottom: 6px;
  right: 5px;
  background-image: linear-gradient(to right, transparent 0%, #972ffe 30%);
}
#between-popover .nearby-start .between-services .start:before,
#between-popover .nearby-end .between-services .end:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 32px;
  bottom: 0;
  border-bottom: 2px dotted #fff;
  background: url(images/walk.svg) no-repeat center;
  background-size: 14px;
}
#between-popover .nearby-start .between-services .start:before {
  left: 0;
}
#between-popover .nearby-end .between-services .end:before {
  right: 0;
}

#between-popover .between-stops {
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 10px;
  display: flex;
  padding-top: 1.2em;
}
#between-popover .between-stops:before,
#between-popover .between-stops:after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  border: 3px solid;
  background-color: #fff;
}
#between-popover .between-stops:before {
  border-color: #f01b48;
  margin-right: 24px;
}
#between-popover .between-stops:after {
  border-color: #972ffe;
  margin-top: 4px;
  margin-left: 24px;
}
#between-popover .between-stops.nada:after {
  margin-top: 0;
}
#between-popover .between-stops .start,
#between-popover .between-stops .end {
  display: none;
}

#between-popover .nearby-start .between-stops .start,
#between-popover .nearby-end .between-stops .end {
  display: block;
}
#between-popover .nearby-start .between-stops .start:before,
#between-popover .nearby-end .between-stops .end:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-color: #f01b48 #972ffe #972ffe #f01b48;
  background-color: #fff;
  border-radius: 100px;
}
#between-popover .nearby-start .between-stops .start:before {
  margin-top: 1px;
  border-color: #f01b48;
}
#between-popover .nearby-end .between-stops .end:before {
  margin-left: auto;
  margin-top: 5px;
  border-color: #972ffe;
}

#between-popover .between-stops .betweens {
  text-align: center;
  display: block;
  padding-top: 3px;
  flex-grow: 1;
}
#between-popover .between-stops.nada .betweens {
  visibility: hidden;
}
#between-popover .between-stops .betweens:before {
  content: '';
  display: block;
  margin: auto;
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-color: #f01b48 #972ffe #972ffe #f01b48;
  background-color: #fff;
  border-radius: 100px;
}
#between-popover .between-stops .betweens-2:before {
  width: 15px;
}
#between-popover .between-stops .betweens-3:before {
  width: 20px;
}
#between-popover .between-stops .betweens-4:before {
  width: 25px;
}
#between-popover .between-stops .betweens-5:before {
  width: 30px;
}
#between-popover .between-stops .betweens-6:before {
  width: 35px;
}

.warning {
  background-color: papayawhip;
  color: saddlebrown;
  font-weight: bold;
}

#arrivals-popover {
  max-height: none;
  /*height: calc(100% - 10px);*/
  height: calc(100% - env(safe-area-inset-top) - 10px);
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.15s ease-in-out;
}
#arrivals-popover .popover-scroll {
  /* overflow: auto; */
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
#arrivals-popover iframe {
  flex-grow: 1;
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 20px);
  border: 0;
  opacity: 0;
  transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s  ease-in-out;
}
#arrivals-popover.expand iframe {
  opacity: 1;
}

#arrivals-popover .popover-close {
  z-index: 1;
  backdrop-filter: blur(10px) saturate(3);
}
#arrivals-popover .popover-popout {
  right: 60px;
  width: auto;
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 14px;
}
#arrivals-popover .popover-popout img {
  vertical-align: text-bottom;
  filter: brightness(0.5);
}
#arrivals-popover .popover-popout:hover img {
  filter: brightness(0.2);
}
.standalone #arrivals-popover .popover-popout {
  display: none;
}

.popover-search {
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 60px;
  display: flex;
}

.popover-search input[type='search'] {
  display: block;
  width: 100%;
  appearance: none;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0 7px;
}
.popover-search input[type='search']:focus {
  background-color: rgba(255, 255, 255, 0.5);
}
.popover-search input[type='search']:focus-visible {
  outline-color: #007aff;
}

.popover-search button {
  display: none;
  height: 30px;
  padding: 0 0 0 14px;
  position: relative;
  color: #007aff;
  cursor: pointer;
  appearance: none;
  border: 0;
  margin: 0;
  background: transparent none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
}
.popover.expand .popover-search button {
  display: inline-block;
}
.popover-search button:active {
  opacity: 0.4;
}

.popover-list {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.popover-list.loading {
  opacity: 0.5;
  pointer-events: none;
}
.popover-list li.nada {
  padding: 8px;
  pointer-events: none;
}
.popover-list li:not(.ads) a {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #000;
}
.popover-list label {
  margin: 0;
}
@media (hover: hover) {
  .popover-list li:not(.ads) a:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.popover-list li a [class*='-tag'] {
  margin-right: 1em;
}
.popover-list li a.current {
  background-color: #f0ad3d4f ;
  backdrop-filter: blur(5px);
  pointer-events: none;
}

.popover-list li:not(.ads) {
  position: relative;
}
.popover-list li:not(.ads) > label:not([hidden]) {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 1px;
  padding: 0 14px 0 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
}
.popover-list li:not(.ads) > label input {
  cursor: pointer;
}

.stops-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.stops-table,
.stops-list {
  margin-bottom: 16px;
}
.stops-list,
.stops-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.stops-table td.stop-start {
  position: relative;
  background: radial-gradient(
    circle at bottom center,
    transparent,
    transparent 11px,
    #f01b48 11px,
    #f01b48 13px,
    transparent 13px
  );
}
.stops-table td.stop-end,
.stops-table td.stop-u {
  position: relative;
  background: radial-gradient(
    circle at top center,
    transparent,
    transparent 11px,
    #f01b48 11px,
    #f01b48 13px,
    transparent 13px
  );
}
.stops-table td.stop-u {
  height: 20px;
}
.stops-list li,
.stops-table td.stop,
.stops-table td.empty {
  position: relative;
  background: linear-gradient(#f01b48, #f01b48) no-repeat 4px 0;
  background-size: 2px 100%;
}
.stops-table td.stop {
  width: calc(50% - 7px);
}
.stops-table td.stop:first-child,
.stops-table td.empty:first-child {
  background-position: right 4px top 0;
}
.stops-list > li:first-child,
.stops-table tr:first-of-type > td.stop,
.stops-table tr > td.stop.first,
.stops-table tr:first-of-type > td.empty {
  background-position: 4px bottom;
  background-size: 2px 50%;
}
.stops-table tr:first-of-type > td.stop:first-child,
.stops-table tr > td.stop.first:first-child,
.stops-table tr:first-of-type > td.empty:first-child {
  background-position: right 4px bottom 0;
}
.stops-list > li:last-child,
.stops-table tr:last-of-type > td.stop,
.stops-table tr > td.stop.last,
.stops-table tr:last-of-type > td.empty {
  background-position: 4px top;
  background-size: 2px 50%;
}
.stops-table tr:last-of-type > td:first-child.stop,
.stops-table tr > td:first-child.stop.last,
.stops-table tr:last-of-type > td:first-child.empty {
  background-position: right 4px top 0;
}
.stops-list li:before,
.stops-table td.stop-start:before,
.stops-table td.stop-end:before,
.stops-table td.stop:before {
  pointer-events: none;
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #f01b48;
  background-color: #fff;
  border-radius: 999px;
}
.stops-table td.stop-start:before {
  bottom: 7px;
  left: calc(50% - 5px);
}
.stops-table td.stop-end:before {
  top: 7px;
  left: calc(50% - 5px);
}
.stops-list li:before,
.stops-table td.stop:before {
  top: calc(50% - 5px);
  left: 0;
}
.stops-table td.stop:first-child:before {
  left: auto;
  right: 0;
  margin: 0 0 0 10px;
}
.stops-list li:nth-of-type(3n + 1):not(:last-of-type):after,
.stops-table tr:nth-of-type(3n + 1) td.stop:not(.last):after,
.stops-table tr:nth-of-type(3n + 1) td.empty:not(.last):after,
.stops-table tr.edge + tr td.stop:not(.last):after,
.stops-table tr.edge + tr td.empty:not(.last):after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #f01b48;
  border-right: 2px solid #f01b48;
  transform: rotate(45deg);
  opacity: 0.5;
}
.stops-table tr:nth-of-type(3n + 1) td.stop:not(.last):first-child:after,
.stops-table tr:nth-of-type(3n + 1) td.empty:not(.last):first-child:after,
.stops-table tr.edge + tr td.stop:not(.last):first-child:after,
.stops-table tr.edge + tr td.empty:not(.last):first-child:after {
  left: auto;
  right: 1px;
}
.stops-table tr td.stop:last-child:after,
.stops-table tr td.empty:last-child:after {
  transform: rotate(-135deg) !important;
}
.stops-list li a,
.stops-table td.stop a,
.stops-table td.stop-start a,
.stops-table td.stop-end a {
  display: block;
  text-decoration: none;
  align-items: center;
  color: #000;
  border-radius: 5px;
  transition: 0.1s background-color ease-in-out;
}
.stops-list li a *,
.stops-table td.stop a *,
.stops-table td.stop-start a *,
.stops-table td.stop-end a * {
  pointer-events: none;
}
.stops-list li:first-child a,
.stops-list li:last-child a,
.stops-table td.stop-start a,
.stops-table td.stop-end:not(.loop) a,
.stops-table td.first a,
.stops-table td.last a {
  font-weight: bold;
}
.stops-list li:not(:first-child):not(:last-child) a,
.stops-table td.stop a {
  font-size: 0.9em;
}
.stops-list li a:hover,
.stops-table td.stop a:hover,
.stops-table td.stop-start a:hover,
.stops-table td.stop-end a:hover {
  background-color: #f0ad3d4f;
}
.stops-list li a.flash,
.stops-table td.stop a.flash,
.stops-table td.stop-start a.flash,
.stops-table td.stop-end a.flash {
  background-color: #f0ad3d4f;
  animation: flash 1s both ease-in;
}
@keyframes flash {
  75% {
    background-color: #f01b4822;
  }
  100% {
    background-color: #f01b4800;
  }
}
.stops-table td.stop-start a {
  padding: 5px 5px 20px 5px;
  text-align: center;
}
.stops-table td.stop-end a {
  padding: 20px 5px 5px 5px;
  text-align: center;
}
.stops-list li a,
.stops-table td.stop a {
  padding: 5px 5px 5px 20px;
}
.stops-table td.stop:first-child a {
  padding: 5px 20px 5px 5px;
  text-align: right;
}
.stops-table col.middle {
  width: 14px;
}

.placeholder {
  color: #aaa;
  letter-spacing: -1px;
  animation: glowing infinite alternate 1s linear both;
}
@keyframes glowing {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.time-ontime {
  color: #1cbd60 !important;
  font-weight: 700 !important;
}
.time-delay {
  color: #ed8e34 !important;
  font-weight: 700 !important;
  border-bottom-style: dotted !important;
  text-decoration: underline dotted !important;
}
.time-delayplus {
  color: #f01b48 !important;
  border-bottom-style: dashed !important;
  text-decoration: underline dashed !important;
}

.service-tag {
  display: inline-block;
  padding: 2px 5px;
  border: 2px solid #000;
  border-radius: 5px;
  background-color: #f0ad3d;
  color: #2c3e50;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  flex-shrink: 0;
  font-variant-numeric: tabular-nums;
}
h1 .service-tag {
  font-weight: bold;
}
a.service-tag:hover {
  color: #000;
}
.service-tag.current,
.current .service-tag {
  color: #000;
  pointer-events: none;
  animation: currenting infinite linear 1s alternate both;
}
.service-tag.has-service,
.has-service .service-tag {
  color: #000;
}
.service-tag.no-service,
.no-service .service-tag {
  display: none;
}
.service-tag.highlight {
  color: #000;
  animation: currenting infinite linear 1s alternate both;
}
@keyframes currenting {
  0% {
    border: 3px solid #e33c6a;
  }
  100% {
    border: 3px solid #cecece;
  }
}
.service-tag span {
  font-weight: 700;
  font-size: 0.75em;
  background-color: #fff;
  color: #000;
  line-height: 0.4;
  padding: 8px;
  margin: 0 -8px 0 5px;
}
.service-tag span.close {
  font-size: 1.5rem;
  color: #000;
}
.service-tag span.close:hover {
  color: #f01b48;
}

.stop-tag {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #f01b4822;
  color: #f01b48;
  border: 1px solid rgba(0,0,0, 0.7);
  flex-shrink: 0;
  font-variant-numeric: tabular-nums;
  font-size: 12px;
  font-weight: 500;
}
.stop-tag-hide {
  display: none;
}
.mini-stop-tag {
  display: inline-block;
  /*display: none;*/
  color: #f01b48;
  font-size: 10px;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
}

#popover-float {
  width: 100%;
  position: absolute;
  top: -14px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  padding: 0 12px;
}
#popover-float > *:first-child {
  transform: translateY(-100%);
}
#popover-float[hidden] {
  display: block;
  opacity: 0;
  pointer-events: none;
}
#popover-float[hidden] > *:first-child {
  transform: translateY(0);
}

.float-pill {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 7px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 12px;
  margin-left: 12px;
}
@supports (backdrop-filter: blur(10px)) {
  .float-pill {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}
.float-pill .service-flex {
  display: flex;
  align-items: flex-start;
  margin-right: 36px;
  padding-bottom: 8px;
}
.float-pill h1 {
  font-size: 1em;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0;
}
.float-pill h2 {
  font-size: 0.8em;
  font-weight: 600;
  margin: 1em 0 0.5em;
  padding: 0;
  color: 383838;
  text-transform: uppercase;
}
.float-pill .services-list > h2:first-child {
  margin-top: 0.5em;
}
.float-pill h2 a {
  color: inherit;
  text-decoration: none;
}
.float-pill h2 a:hover {
  color: inherit;
  text-decoration: underline;
}
.float-pill p {
  font-size: 0.8em;
  margin: 0;
  padding: 0;
}
.float-pill .service-tag,
.float-pill .stop-tag {
  margin-right: 8px;
  flex-shrink: 0;
  vertical-align: middle;
}
.float-pill .service-info {
  flex-basis: 85%;
}
.float-pill .services-list {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 25vh;
}
.float-pill .services-list .service-tag {
  margin-top: 4px;
}
.popover-back {
  position: absolute;
  top: 7px;
  right: 40px;
  height: 24px;
  width: 24px;
  display: inline-block;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}
.popover-back:hover {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}
.popover-back:active {
  filter: brightness(0.9);
  transform: scale(0.95);
}
.popover-back > * {
  pointer-events: none;
}
.popover-back-mobile {
  position: absolute;
  top: 0;
  right: 42px;
  display: inline-block;
  text-align: center;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.4);
  text-decoration: none;
}
.popover-back-mobile:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.popover-back-mobile:active {
  filter: brightness(0.9);
  transform: scale(0.95);
}
.popover-close {
  position: absolute;
  top: 7px;
  right: 10px;
  height: 24px;
  width: 24px;
  display: inline-block;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}
.popover-close:hover {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}
.popover-close:active {
  filter: brightness(0.9);
  transform: scale(0.95);
}
.popover-close > * {
  pointer-events: none;
}
.popover-close-mobile {
  position: absolute;
  top: 0;
  right: 12px;
  display: inline-block;
  font-family: inherit;
  text-align: center;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.popover-close-mobile:hover {
  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}
.popover-close-mobile:active {
  filter: brightness(0.9);
  transform: scale(0.95);
}
.popover.expand #popover-float {
  opacity: 0;
}
.popover-buttons {
  display: flex;
}
.popover-button {
  flex: 1;
  background-color: #e1ecf4;
  border-radius: 50px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.15385;
  margin-left: 7px;
  outline: none;
  padding: 11px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}
.popover-button:first-child {
  margin-left: 0;
}
.popover-button:focus,
.popover-button:hover {
  background-color: #b3d3ea;
  color: #2c5777;
}
.popover-button:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}
.popover-button:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}
.popover-button img {
  vertical-align: text-bottom;
}
.popover-button * {
  pointer-events: none;
}

#tooltip {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 8px;
  transition: opacity 0.3s ease-in-out;
  margin-top: 1.5em;
  white-space: nowrap;
  font-size: 80%;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
@supports (backdrop-filter: blur(10px)) {
  #tooltip {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}
#tooltip.show {
  opacity: 1;
}
/* Geolocation */
.mapboxgl-ctrl-pitchtoggle-3d{
  background-position: center;
}
.mapboxgl-ctrl-pitchtoggle-2d{
  background-position: center;
}
.mapboxgl-ctrl-custom-geolocate {
  color: #333;
  transition: color 0.3s;
}
.mapboxgl-ctrl-custom-geolocate.loading {
  color: #007aff;
  pointer-events: none;
  animation: glowing infinite alternate 1s linear both;
}
.mapboxgl-ctrl-custom-geolocate.active {
  color: #007aff;
}
.mapboxgl-ctrl-custom-geolocate svg {
  vertical-align: middle;
  fill: currentColor;
}
.mapboxgl-ctrl-custom-geolocate.active svg .inner {
  fill: currentColor;
}
.user-location {
  perspective: 20px;
  pointer-events: none;
}
.user-location-dot {
  width: 20px;
  height: 20px;
  padding: 4px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 123123px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.user-location-dot:after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  overflow: hidden;
  background-color: #007aff;
  border-radius: 123123px;
  animation: wobble 1.5s ease-out infinite alternate both;
}
@keyframes wobble {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.2);
  }
}
.user-location-compass {
  width: 6px;
  height: 20px;
  background: linear-gradient(rgba(0, 122, 255, 0), #007aff);
  position: absolute;
  left: -3px;
  bottom: 0;
  transform-origin: bottom center;
  transform: rotateX(-60deg);
  transition: transform 0.3s;
}
.user-location-accuracy {
  width: 20px;
  height: 20px;
  overflow: hidden;
  background-color: #007aff;
  border-radius: 123123px;
  position: absolute;
  margin: -10px 0 0 -10px;
  animation: pulse 2s 1s ease-out infinite both;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

/* Scroll shadows */

[data-scroll-shadow='top'] {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}
[data-scroll-shadow='bottom'] {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  background-position: bottom;
  animation: glowing-gradient 1s linear 2s 10 alternate both;
}
[data-scroll-shadow='both'] {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
    linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
  background-position: top, bottom;
}
[data-scroll-shadow] {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100% 16px;
}
@keyframes glowing-gradient {
  0% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  }
  6.25% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1.75));
  }
  12.5% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  }
  18.75% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.225));
  }
  25% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
  }
  100% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
  }
}

/* Live badge */

.live {
  display: inline-block;
  font-size: 0.9em;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 2px;
  /* animation: glowing infinite alternate 1s linear both; */
  color: #fff;
  background-color: #00454d;
  text-transform: uppercase;
}

/* Fancy pants features */

[class*='alt-show'] {
  display: none;
}
.alt-mode .alt-show-inline {
  display: none;
}
.alt-mode .alt-show-block {
  display: block;
}
.alt-mode .alt-show-flex {
  display: flex;
}
.alt-mode .alt-hide {
  display: none;
}

/* Standalone-mode styles */

.standalone:before {
  content: '';
  display: block;
  height: env(safe-area-inset-top);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.standalone {
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/* Plus */

.plus {
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 999px;
  background-color: #55dd3333;
  font-weight: bold;
  text-decoration: none;
  overflow: hidden;
  width: 22px;
  height: 22px;
  line-height: 0;
  text-align: center;
  padding: 0;
  margin: 2px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s ease-in-out;
  vertical-align: middle;
}
.plus:before,
.plus:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #3a6727;
  border-radius: 2px;
  transition: transform 0.3s ease-in-out;
}
.plus:before {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}
.plus:after {
  height: 2px;
  width: 10px;
  top: 8px;
  left: 4px;
}
.plus:hover {
  border-color: #3a6727;
}
.plus:hover:before,
.plus:hover:after {
  transform: rotate(180deg);
}
.plus:active {
  filter: brightness(0.8);
}

/* Simple stops list */

.simple-stops-list,
.simple-stops-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.simple-stops-list li a {
  font-size: 12px;
  font-weight: 500;
  padding: 0 8px;
  display: block;
  text-decoration: none;
  align-items: center;
  color: #000;
  border-radius: 5px;
  transition: 0.1s background-color ease-in-out;
  display: flex;
}
.simple-stops-list li a:hover {
  background-color: #f0ad3d4f;
}
.simple-stops-list .stop-tag {
  font-weight: normal;
}
.simple-stops-list .stop-name {
  flex-grow: 1;
}
.simple-stops-list .services-meta-list {
  display: inline-block;
  text-align: right;
  font-weight: 600;
  color: #000;
  text-shadow: 0 0 2px #eeffd1;
  word-spacing: 0.5em;
  margin-left: 4px;
  padding: 4px;
}

/* checkbox */

.popover input[type='checkbox'] {
  position: relative;
  width: 24px;
  height: 24px;
  appearance: none;
  padding: 0;
  margin: 0;
}

.popover input[type='checkbox']:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 2px solid #8d8d8d;
  background-color: #F2F3F4;
  transition: 0.1s all ease-in-out;
  backdrop-filter: blur(10px);
}
.popover input[type='checkbox']:active:before {
  background-color: #D7DBDD;
}
.popover input[type='checkbox']:hover:before, input[type='checkbox']:checked:before {
  border-color: #909497;
}
.popover input[type='checkbox']:after {
  opacity: 0;
  content: '';
  position: absolute;
  top: 6px;
  left: 9px;
  border: solid #8d8d8d;
  border-width: 0 2px 2px 0;
  width: 6px;
  height: 10px;
  transform: rotate(-45deg);
  transition: 0.3s all ease-in-out;
}
.popover input[type='checkbox']:active:after, input[type='checkbox']:checked:after {
  opacity: 1;
  transform: rotate(45deg);
}
/* Locale selector */
#locale-selector select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.25);
  background-image: none;
  border: 0;
  margin: 0 8px 0 0;
  padding: 5px;
}
#locale-selector select:hover,
#locale-selector select:active,
#locale-selector select:focus {
  background-color: rgba(255, 255, 255, 0.5);
}
.mapboxgl-ctrl-geocoder {
  position: absolute;
  right: 40px;
  width: 500px;
}
div.mapboxgl-ctrl-top-right > div:nth-child(2) {
  position: relative;
  z-index: 1000;
}
div.mapboxgl-ctrl-top-right > div:nth-child(2) button {
  display: block!important;
}
.mapboxgl-ctrl-top-right .mapboxgl-style-list {
  border-radius: 4px;
  position: absolute;
  z-index: 10000;
  background-color: #ffffff;
  right: 0;
  top: 44px;
}
#map .mapboxgl-ctrl-geocoder {
  display: none;
}
#geocoder {
  display: none;
  position: fixed;
}
button.mapboxgl-ctrl-custom-geolocate {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* SVG's Controls */
#map button.mapboxgl-style-switcher {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65%;
  background-image: url("./images/style_map.svg") !important;
  /*    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTQuODQ5cHgiIGhlaWdodD0iNTQuODQ5cHgiIHZpZXdCb3g9IjAgMCA1NC44NDkgNTQuODQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NC44NDkgNTQuODQ5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PGc+PHBhdGggZD0iTTU0LjQ5NywzOS42MTRsLTEwLjM2My00LjQ5bC0xNC45MTcsNS45NjhjLTAuNTM3LDAuMjE0LTEuMTY1LDAuMzE5LTEuNzkzLDAuMzE5Yy0wLjYyNywwLTEuMjU0LTAuMTA0LTEuNzktMC4zMThsLTE0LjkyMS01Ljk2OEwwLjM1MSwzOS42MTRjLTAuNDcyLDAuMjAzLTAuNDY3LDAuNTI0LDAuMDEsMC43MTZMMjYuNTYsNTAuODFjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMEw1NC40ODgsNDAuMzNDNTQuOTY0LDQwLjEzOSw1NC45NjksMzkuODE3LDU0LjQ5NywzOS42MTR6Ii8+PHBhdGggZD0iTTU0LjQ5NywyNy41MTJsLTEwLjM2NC00LjQ5MWwtMTQuOTE2LDUuOTY2Yy0wLjUzNiwwLjIxNS0xLjE2NSwwLjMyMS0xLjc5MiwwLjMyMWMtMC42MjgsMC0xLjI1Ni0wLjEwNi0xLjc5My0wLjMyMWwtMTQuOTE4LTUuOTY2TDAuMzUxLDI3LjUxMmMtMC40NzIsMC4yMDMtMC40NjcsMC41MjMsMC4wMSwwLjcxNkwyNi41NiwzOC43MDZjMC40NzcsMC4xOSwxLjI1MSwwLjE5LDEuNzI5LDBsMjYuMTk5LTEwLjQ3OUM1NC45NjQsMjguMDM2LDU0Ljk2OSwyNy43MTYsNTQuNDk3LDI3LjUxMnoiLz48cGF0aCBkPSJNMC4zNjEsMTYuMTI1bDEzLjY2Miw1LjQ2NWwxMi41MzcsNS4wMTVjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMGwxMi41NDEtNS4wMTZsMTMuNjU4LTUuNDYzYzAuNDc3LTAuMTkxLDAuNDgtMC41MTEsMC4wMS0wLjcxNkwyOC4yNzcsNC4wNDhjLTAuNDcxLTAuMjA0LTEuMjM2LTAuMjA0LTEuNzA4LDBMMC4zNTEsMTUuNDFDLTAuMTIxLDE1LjYxNC0wLjExNiwxNS45MzUsMC4zNjEsMTYuMTI1eiIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);*/
}
#map button.mapboxgl-search-switcher {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  background-image: url("./images/search.svg");
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='mapboxgl-ctrl-geocoder--icon mapboxgl-ctrl-geocoder--icon-search' viewBox='0 0 18 18' xml:space='preserve' width='18' height='18'%3E%3Cpath d='M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z'%3E%3C/path%3E%3C/svg%3E");*/
}
#map button.mapboxgl-agencies-switcher {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  background-image: url("./images/filters.svg");
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M184.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM256 96c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zM192 416c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zM80 464c-26.5 0-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48z'/%3E%3C/svg%3E");*/
}
/* Footer */
.footer-container .fender-logos img{
  max-width: 380px;
  width: 100%;
}
.footer-container .mun-logos {
  display: flex!important;
  justify-content: right;
}
.footer-container .mun-logos img{
  max-width: 500px;
  width: 100%;
}
.footer-container {
  position: fixed;
  width: 100%;
  min-height: 70px;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 70px;
}
.footer-container > div {
  display: grid;
  grid-template-columns: 30% 1fr 40%;
}
.footer-copy{
  color: #fff;
  margin: 0;
  font-family: inherit;
  font-size: 12px;
}
.footer-copy-dev{
  color: #fff;
  margin: 0;
  font-family: inherit;
  font-size: 10px;
}
.footer-copy-dev a{
  color: #adcb3d;
  transition: all .3s ease-in;
}
.footer-copy-dev a:hover{
  color: #73b72b;
  text-decoration: underline;
}
.footer-copy-dev-hidden{
  color: #000000;
  margin: 0;
  display: none;
}
/* Filters */
.agency-popover {
  display: none;
  background-color: #fff;
  border-radius: 4px;
  font-size: 12px;
  padding: 12px;
  z-index: 1;
  width: 170px;
  position: fixed;
}
.agency-popover .list-container > div {
  align-items: center;
  padding: 3px 0;
}
.Toast-Notification {
  z-index: 9 !important;
  margin-top: 100px;
}
.delay1-tag {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-radius: 50px;
  background-color: #2ecc71;
  animation: blink 3s ease-in-out infinite;
}
.delay2-tag {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-radius: 50px;
  background-color: #ff9f43;
  animation: blink 3s ease-in-out infinite;
}
.delay3-tag {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-radius: 50px;
  background-color: #f01b48;
  animation: blink 3s ease-in-out infinite ;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.title-arrivals{
  margin-bottom: 10px;
}
.title-arrivals p {
  font-weight: 700;
  font-size: 12px;
  margin: 0 5px;
}
/* ------------ Responsive -------------- */
@media (min-width: 481px) {
  /* Reset the safe area paddings */
  #between-popover .popover-scroll,
  #stop-popover .popover-footer {
    padding-left: 12px;
    padding-right: 12px;
  }
  #popover-float {
    padding: 0;
  }
  .float-pill {
    right: 0;
    margin-left: 0;
  }
  .mapboxgl-ctrl-bottom-left {
    transform: translateY(0) !important;
  }
}
@media (min-width: 481px) and (min-height: 551px) {
  #stop-popover,
  #between-popover {
    max-height: 70vh;
  }
}
@media (min-width: 641px) {
  #stop-popover,
  #between-popover,
  #service-popover {
    max-height: calc(100vh - 230px);
  }
  .float-pill .services-list {
    max-height: 35vh;
  }
}
@media (max-width: 700px) {
  .mapboxgl-ctrl-geocoder {
    width: 260px;
  }
}
@media (min-width: 1200px) and (min-height: 600px) and (orientation: landscape) {
  #map {
    right: 400px;
    left: 0;
    width: auto;
  }
  #logo {
    z-index: 4;
  }
  #search-popover,
  #search-popover.expand,
  #search-popover.shrink {
    width: 400px;
    height: auto;
    inset: 100px 0 70px auto;
    transform: none;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: linear-gradient(to right, #c9d6ff , #e2e2e2);
  }
  #search-popover .popover-inner {
    border-radius: 0;
  }
  #search-popover .popover-list {
    transition: opacity 0.3s ease-in-out;
  }
  #search-popover.shrink:hover .popover-list {
    opacity: 1;
  }
  .popover-search {
    padding: 22px 14px 22px 14px;
    min-height: auto;
  }
  .popover.expand .popover-search button {
    display: none;
  }
  .popover #popover-float {
    top: 120px;
    left: 15px;
    position: fixed;
    max-width: 380px;
    z-index: 10000;
  }
  .popover {
    max-width: 480px;
    right: 16px;
  }
  @supports (top: max(1px)) {
    .popover {
      left: max(16px, env(safe-area-inset-right));
    }
  }
  .popover.expand #popover-float:not([hidden]) {
    opacity: 1;
  }
  .float-pill {
    transform: none !important;
    left: 0;
    right: auto;
  }
  .plus {
    display: none !important;
  }
  @media (hover: hover) {
    .popover-list li:not(.ads) > label:not([hidden]) {
      transition: all 0.3s ease-in-out;
      transition-delay: 0.3s;
      opacity: 0;
      transform: translateX(100%);
      pointer-events: none;
    }
    .popover-list li:not(.ads):nth-child(even) > label:not([hidden]) {
      transition-delay: 0.35s;
    }
    .popover-list:hover li:not(.ads) > label:not([hidden]) {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }
  }
}
@media (max-width: 1200px) {
  .footer-container .mun-logos, .footer-container .text-center {
    display: none !important;
  }
  .footer-container {
    min-height: 60px;
    max-height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .footer-container > div {
    display: flex;
    justify-content: center!important;
  }
  #stop-popover, #between-popover, #arrivals-popover, #service-popover {
    top: calc(100% - 60px)!important;
  }
  .navbar{
    display: none;
  }
  #map {
    top: 0!important;
    bottom: 60px!important;
  }
  .mapboxgl-ctrl-top-left {
    top: 60px;
  }
  .responsive-logo {
    display: block!important;
  }
  .agency-popover {
    margin-top: 55px;
  }
  .Toast-Notification{
    margin-top: 0;
  }
  .popover-list{
    margin: 0 0 200px 0;
  }
}
